/* Estilo global para garantir uma boa aparência */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f4f9;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.5rem;
  color: #d32f2f; /* Um vermelho sofisticado */
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.2;
}

p {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
  max-width: 600px;
  line-height: 1.6;
  margin: 0 auto 1.5rem auto;
}

a {
  color: #d32f2f;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

a:hover {
  color: #b71c1c; /* Um tom mais escuro para o hover */
}

div {
  background: #fff;
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  
  p {
    font-size: 1rem;
  }

  div {
    padding: 1.5rem;
  }
}
.countdown {
  font-size: 2rem;
  font-weight: bold;
  color: #FF5722; /* Laranja vibrante */
  background-color: #FFF3E0; /* Fundo claro */
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  margin-left: 10px;
}
